<template>
  <div class="modal-mask works-swiper-modal">
    <div class="back-drop" @click="close()"></div>
    <div class="modal-wrapper">
      <div class="modal-container">
        <!-- <button class="modal-default-button" @click="close()">OK</button> -->
        <div class="close" @click="close()"></div>

        <div class="modal-body">
          <slot name="body">
            <swiper class="workSwiper" ref="mySwiper" :options="swiperOptions">
              <swiper-slide v-for="{ name, description, id, image } in works" :key="id">
                <div class="slide-content">
                  <div class="outer mb-3">
                    <!-- <img
                      class="inner"
                      :src="`${stroageUrl}/${JSON.parse(image)[0]}`"
                      alt=""
                      data-swiper-parallax="60%"
                    />-->

                    <swiper
                      class="workInnerSwiper inner"
                      ref="myInnerSwiper"
                      :options="innerSwiperOptions"
                    >
                      <swiper-slide v-for="(el, i) in JSON.parse(image)" :key="i">
                        <img v-if="!el.includes('.mp4')" :src="`${stroageUrl}/${el}`" alt />
                        <video v-if="el.includes('.mp4')" muted controls loop playsinline>
                          <source :src="`${stroageUrl}/${el}`" type="video/mp4" />
                        </video>
                      </swiper-slide>

                      <!-- Add Pagination -->
                      <div
                        v-if="JSON.parse(image).length > 1"
                        class="swiper-pagination"
                        slot="pagination"
                      ></div>
                    </swiper>
                  </div>

                  <div class="text-content">
                    <h2>{{ name }}</h2>
                    <p>{{ description }}</p>
                  </div>
                </div>
              </swiper-slide>

              <!-- Add Arrows -->
              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>
            </swiper>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from '@/main'
export default {
  props: ['works', 'selectedWork'],
  data () {
    return {
      stroageUrl: process.env.VUE_APP_STORAGE_URL,
      swiperOptions: {
        initialSlide: this.selectedWork,
        effect: 'fade',
        resizeObserver: true,
        loop: true,
        navigation: {
          nextEl: '.workSwiper .swiper-button-next',
          prevEl: '.workSwiper .swiper-button-prev'
        },

        on: {
          init: (swiper) => {
          },
          beforeDestroy: (swiper) => {
          }
        }
      },
      innerSwiperOptions: {
        direction: 'vertical',
        resizeObserver: true,

        pagination: {
          el: '.workInnerSwiper .swiper-pagination',
          clickable: true
        }
      }
    }
  },
  computed: {
    workSwiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
  methods: {
    close () {
      bus.$emit('closeModal')
    }
  },
  mounted () {
    // this.workSwiper.slideTo(this.selectedWork, 0, false)
  }
}
</script>

<style lang="scss">
.works-swiper-modal {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: table;
  transition: opacity 0.3s ease;
  .back-drop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .swiper-slide {
    overflow: hidden;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 100vw;
    max-width: 100vw;
    margin: 0px auto;
    //   padding: 20px 30px;
    background-color: transparent;
    border-radius: 2px;
    //   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    //   font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    //   margin: 20px 0;
    padding: 0;
  }

  .workSwiper {
    overflow: inherit;

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: -50px;
      left: auto;
      &::after {
        color: #fff;
      }
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: -50px;
      right: auto;
      &::after {
        color: #fff;
      }
    }
  }

  .outer {
    position: relative;
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: (1 / 1) * 100%;
    }
    > .inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .swiper-pagination-bullet {
    background: #999;
    opacity: 0.8;
    &-active {
      opacity: 1;
      background: #fff;
    }
  }

  // .modal-default-button {
  //   float: right;
  // }
  .swiper-slide {
    img,
    video {
      opacity: 0;
      // height: 100%;
      width: 100%;
      object-fit: contain;
      background: #000;
    }
  }
  .swiper-slide-prev,
  .swiper-slide-next,
  .swiper-slide-active {
    img,
    video {
      transition: all 0.5s ease;
      opacity: 1;
      object-position: right;
    }
  }

  .workSwiper .text-content {
    padding: 0 22px;
  }

  .workSwiper .text-content > * {
    opacity: 0;
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .workSwiper .swiper-slide-active .text-content > * {
    opacity: 1;
    transform: none;
  }

  // .workSwiper .swiper-slide-active .text-content > :first-child {
  //   -webkit-transition-delay: 0.3s;
  //   transition-delay: 0.3s;
  // }

  .workInnerSwiper {
    .swiper-slide {
      display: flex;
      align-items: center;
      background: #000;
    }
  }

  @for $i from 1 through 10 {
    .workSwiper .swiper-slide-active .text-content > :nth-child(#{$i}) {
      transition-delay: #{0.2 + 0.1 * $i}s;
    }
  }

  @media (min-width: 768px) {
    .modal-container {
      width: 70vh;
    }
    .workSwiper .text-content {
      padding: 0;
    }
  }
}
</style>
